import React, { useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import routeConfiguration from '../../../routing/routeConfiguration';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';

import { ModalInMobile, Button } from '../../../components';

import css from './SearchFiltersMobile.module.css';

import BackArrow from '../../../assets/icons/back-arrow.png';
import FilterIcon from '../../../assets/icons/filters-24.png'

const SearchFiltersMobileComponent = (props) => {
  const {
    history,
    onCloseModal,
    initialQueryParams,
    rootClassName,
    className,
    children,
    resultsCount,
    showAsModalMaxWidth,
    onManageDisableScrolling,
    intl,
    hideTopBar,
    resetAll
  } = props;

  // Close the filters by clicking cancel, revert to the initial params
  const cancelFilters = () => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, initialQueryParams));
    onCloseModal();
  }

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  const classes = classNames(css.root);

  const modalCloseButtonMessage = intl.formatMessage({ id: 'actions.cancel' });

  const showListingsLabel = intl.formatMessage({ id: 'search_page.mobile.show_listings' }, { count: resultsCount });

  const toggleFiltersModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  }

  return (
    <div className={classes}>
      <button className={css.openFiltersButton} onClick={() => toggleFiltersModal()} type="button">
        <img className={css.filterButtonIcon} src={FilterIcon} alt='Filter icon' />
        <span className={css.filterButtonTitle}>{intl.formatMessage({ id: 'search_page.mobile_filters.label' })}</span>
      </button>
      <ModalInMobile
        id="SearchFiltersMobile.filters"
        isModalOpenOnMobile={isFilterModalOpen}
        onClose={() => cancelFilters()}
        showAsModalMaxWidth={showAsModalMaxWidth}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        closeButtonMessage={modalCloseButtonMessage}
        modalTitle="Filters"
        hideTopBar={hideTopBar}
      >
        {isFilterModalOpen ? (
          <>
            <div className={css.filterTopBar}>
              <img onClick={() => toggleFiltersModal()} className={css.topBarBackArrow} src={BackArrow} alt='Back arrow'/>
              <label className={css.topBarLabel}>
                {intl.formatMessage({ id: 'search_page.mobile_filters.label' })}
              </label>
              <span className={css.topBarResetAll} onClick={() => resetAll()}>
                {intl.formatMessage({ id: 'search_page.mobile_filters.reset' })}
              </span>
            </div>
            <div className={css.filtersWrapper}>{children}</div>
          </>
        ) : null}

        <div className={css.showListingsContainer}>
          <Button className={css.showListingsButton} onClick={() => toggleFiltersModal()}>
            {showListingsLabel}
          </Button>
        </div>
      </ModalInMobile>
    </div>
  );
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  resultsCount: number,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onCloseModal: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
