import { Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import omit from 'lodash/omit';
import unionWith from 'lodash/unionWith';
import { array, bool, func, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import SearchEmptyStateIcon from '../../assets/icons/search-empty-state.png';
import { Footer, LayoutWrapperTopbar, NamedLink, Page } from '../../components';
import { JoinCommunityModal } from '../../components/LeadsModal/JoinCommunityLeadsModal';
import Loader from '../../components/Loader/Loader';
import Spacer, { MEDIUM_SPACER } from '../../components/Spacer/Spacer';
import { brandsConfig } from '../../config/brands-config';
import {
  listingCategoriesRoutingConfiguration,
  listingCategoriesRoutingConfigurationNew,
} from '../../config/helpers/listingCategories';
import { partsConfig } from '../../config/parts-config';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { addCurrentUserFavoriteListing, removeCurrentUserFavoriteListing } from '../../ducks/user.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive, isMainSearchTypeKeywords } from '../../util/search';
import { propTypes } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FilterComponent from './FilterComponent';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import { setActiveListing, updateSearchPagePreviousPath } from './SearchPage.duck';
import {
  createSearchResultSchema,
  getSearchCategoryTitleKey,
  validFilterParams,
  validURLParamsForExtendedData,
} from './SearchPage.helpers';
import css from './SearchPage.module.css';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import config from '../../config';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

const validUrlQueryParamsFromProps = props => {
  const { location, filterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  return validURLParamsForExtendedData(searchInURL, filterConfig);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(sortConfig.conflictingFilters, searchParams, filterConfig);
  return sortingFiltersActive ? { ...searchParams, [sortConfig.queryParamName]: null } : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQueryParams: validUrlQueryParamsFromProps(props),
    };

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  componentDidMount() {
    const { updatePreviousPath, history } = this.props;

    const prevPath = history.location?.state?.prevPath;

    if (prevPath && !prevPath.includes('search')) {
      updatePreviousPath(prevPath);
    }
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll() {
    const { history, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, {}));
  }

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const { currentQueryParams } = this.state;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => ({ ...acc, [paramName]: getInitialValue(paramName) }), {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};

        const updatedURLParamsKeys = Object.keys(updatedURLParams);
        const updateURLParamsIncludeCategory = updatedURLParamsKeys.some(key =>
          ['pub_listingType', 'pub_category', 'pub_subCategory'].includes(key)
        );

        if (updateURLParamsIncludeCategory) {
          return { currentQueryParams: { ...updatedURLParams } };
        }

        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const filteredsearchParams = Object.fromEntries(
            Object.entries(this.state.currentQueryParams).filter(([key, value]) => value !== 'undefined')
          );
          const search = cleanSearchFromConflictingParams(filteredsearchParams, sortConfig, filterConfig);

          const pathParams =
            search.pub_listingType === undefined &&
            search.pub_category === undefined &&
            search.pub_subCategory === undefined
              ? this.props.match.params
              : {
                  type: search.pub_listingType,
                  category: search.pub_category,
                  subCategory: search.pub_subCategory,
                };

          const queryParams = Object.fromEntries(
            Object.entries(search).filter(
              ([key, _value]) => !['pub_listingType', 'pub_category', 'pub_subCategory'].includes(key)
            )
          );

          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), pathParams, queryParams));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values ? { ...urlQueryParams, [urlParam]: values } : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      onActivateListing,
      onLocaleChange,
      currentLocale,
      likedListingsIds,
      addUserFavorite,
      removeUserFavorite,
      currentUserId,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const listingType = this.props.match.params.type;
    const listingCategory = this.props.match.params.category;
    const listingSubCategory = this.props.match.params.subCategory;

    const availableSpecifications = partsConfig
      .find(partConfig => partConfig.id === listingType)
      ?.config.options.find(category => category.key === listingCategory)
      ?.subcategories?.find(subCategory => subCategory.key === listingSubCategory)
      ?.specifications.filter(spec => spec.isFilterable === true);

    const availableSubSpecifications = availableSpecifications
      ?.map(spec => spec.config.options?.map(subSpec => subSpec.options))
      ?.flat(Infinity)
      ?.filter(spec => spec !== undefined)
      ?.filter((obj, index, self) => index === self.findIndex(o => o.id === obj.id));

    const brands = brandsConfig[listingType] || brandsConfig[listingSubCategory] || [];
    const partConditions = partsConfig.find(partConfig => partConfig.id === 'condition');

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const availableFilters = isKeywordSearch
      ? filterConfig.filter(f => f.type !== 'KeywordFilter' && f.isFilterable === true)
      : filterConfig;

    const baseFilters = availableFilters.filter(f => f.isBaseFilter === true);
    const bikeFilters = availableFilters.concat(brands);
    const partFilters = baseFilters.concat(brands).concat(partConditions);

    bikeFilters.sort((a, b) => a.sortingOrder - b.sortingOrder);

    const filterSpecifications =
      listingType === undefined
        ? baseFilters
        : listingType === 'bike'
        ? bikeFilters
        : availableSpecifications === undefined
        ? partFilters
        : partFilters.concat(availableSpecifications).concat(availableSubSpecifications);

    // Selected aka active filters
    const selectedFilters = validFilterParams(validQueryParams, filterConfig);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && hasPaginationInfo;

    const host = typeof window !== 'undefined' && window.location && window.location.host;

    const { title, description, schema } = createSearchResultSchema(
      host,
      listings,
      searchInURL || {},
      intl,
      listingType,
      listingCategory
    );

    const hasNoResult = listingsAreLoaded && totalItems === 0;

    const noResultsInfo = hasNoResult ? (
      <div className={css.searchPageEmptyStateWrapper}>
        <div className={css.searchPageEmptyStateRoot}>
          <img className={css.searchPageEmptyStateIcon} src={SearchEmptyStateIcon} />
          <Spacer size={MEDIUM_SPACER} />
          <p className={css.searchPageEmptyStateMessage}>
            {intl.formatMessage({ id: 'search_page.empty_state.message' })}
          </p>
        </div>
      </div>
    ) : null;

    const categoriesRountingConfiguration =
      process.env.REACT_APP_SNOW_UPLOAD_ENABLED === '1'
        ? listingCategoriesRoutingConfigurationNew
        : listingCategoriesRoutingConfiguration;

    const availableCategoriesRouting = categoriesRountingConfiguration.find(t => t.key === listingType)?.categories;
    const availableRoutings =
      listingType === undefined
        ? categoriesRountingConfiguration
        : listingCategory === undefined
        ? availableCategoriesRouting
        : null;

    return (
      <Page scrollingDisabled={scrollingDisabled} description={description} title={title} schema={schema}>
        <LayoutWrapperTopbar className={css.topbarRoot}>
          <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale}/>
        </LayoutWrapperTopbar>
        <div className={css.container}>
          <div className={css.searchResultContainer}>
            <Stack gap={1.5} mt={4} mx={{ xs: 2, md: 3 }} sx={{ '@media (min-width: 1360px)': { mx: 'unset' } }}>
              <Typography component="h1" fontSize={20} fontWeight={600}>
                {intl.formatMessage({
                  id: `search_page.title.${getSearchCategoryTitleKey(listingCategory, listingType)}`,
                })}
              </Typography>
              <Typography component="h2" fontSize={14} color="#495057">
                {intl.formatMessage({
                  id: `search_page.subtitle.${getSearchCategoryTitleKey(listingCategory, listingType)}`,
                })}
              </Typography>
            </Stack>
            <Spacer size={8}/>
            {availableRoutings && (
              <div className={css.categorySelectionRoot}>
                <div className={css.categorySelectionContainer}>
                  {availableRoutings.map(type => (
                    <NamedLink
                      to={{ search: stringify({ countryCode: this.state.currentQueryParams.countryCode }) }}
                      key={type.key}
                      name="SearchPage"
                      params={type.pathParams}
                    >
                      <div className={css.categorySelection}>
                        <span className={css.categoryTitle}>
                          {intl.formatMessage({ id: `categories.${type.key}` })}
                        </span>
                      </div>
                    </NamedLink>
                  ))}
                </div>
              </div>
            )}
            <SearchFiltersMobile
              className={css.searchFiltersMobileMap}
              urlQueryParams={validQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onManageDisableScrolling={onManageDisableScrolling}
              initialQueryParams={this.state.initialQueryParams}
              resetAll={this.resetAll}
              selectedFiltersCount={selectedFiltersCountForMobile}
              noResultsInfo={noResultsInfo}
              hideTopBar
            >
              {filterSpecifications.map(config => (
                <FilterComponent
                  key={`SearchFiltersMobile.${config.id}`}
                  idPrefix="SearchFiltersMobile"
                  filterConfig={config}
                  urlQueryParams={validQueryParams}
                  initialValues={this.initialValues}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  liveEdit
                  showAsPopup={false}
                  intl={intl}
                />
              ))}
            </SearchFiltersMobile>
            <div className={css.dividerSmall} />
            <div className={css.desktopWrapper}>
              <SearchFiltersPrimary>
                {filterSpecifications.map(config => (
                  <FilterComponent
                    key={`SearchFiltersMobile.${config.id}`}
                    idPrefix="SearchFiltersMobile"
                    filterConfig={config}
                    urlQueryParams={validQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    showAsPopup={false}
                    liveEdit
                    intl={intl}
                  />
                ))}
              </SearchFiltersPrimary>

              <div
                className={classNames(css.listingsForMapVariant, {
                  [css.newSearchInProgress]: !listingsAreLoaded,
                })}
              >
                {searchListingsError ? (
                  <h2 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </h2>
                ) : null}
                {searchInProgress ? (
                  <div className={css.loaderWrapper}>
                    <Loader />
                  </div>
                ) : null}
                {noResultsInfo || null}
                {!searchInProgress && !hasNoResult && (
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={listings}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={parse(location.search)}
                    pathParams={this.props.match?.params}
                    setActiveListing={onActivateListing}
                    intl={intl}
                    likedListingsIds={likedListingsIds}
                    addUserFavorite={addUserFavorite}
                    removeUserFavorite={removeUserFavorite}
                    currentUserId={currentUserId}
                    resultsCount={totalItems}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <JoinCommunityModal />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchMapListingIds,
    activeListingId,
    previousPath,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const { currentUserFavoriteListingsIds, currentUser } = state.user;

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    activeListingId,
    likedListingsIds: currentUserFavoriteListingsIds,
    currentUserId: currentUser?.id?.uuid,
    previousPath,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  addUserFavorite: (listingId, listingType) => dispatch(addCurrentUserFavoriteListing(listingId, listingType)),
  removeUserFavorite: listingId => dispatch(removeCurrentUserFavoriteListing(listingId)),
  updatePreviousPath: path => dispatch(updateSearchPagePreviousPath(path)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), injectIntl)(SearchPageComponent);

export default SearchPage;
